<template>
  <div class="columns h-100">
    <div class="column is-12 h-100 center-flex d-flex">
      <v-main>

        <v-container>

          <div class="py-3 mb-3">
            <div class="top-text my-3">
<!--              <h1 class="title is-1 thin black&#45;&#45;text">Ура</h1>-->
              <h1 class="title is-1 black--text text-center">Ура</h1>
            </div>
          </div>

          <v-row justify="center" align="center">
            <v-col cols="12" md="8">
              <div class="text-center">
                <h2 class="title is-3 mb-4">
                  Вы успешно подтвердили телефон и теперь можете полноценно
                  пользоваться нашим сервисом. Спасибо и приятного пользования!</h2>
                <b-button
                  @click="$router.push('/activity-feed')"
                  type="is-success is-light">
                  На главную
                </b-button>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmSuccess',
  data() {
    return {
      // Флаг для отслеживания перенаправления пользователя
      redirected: false
    };
  },
  mounted() {
  },
  methods: {
  },
};

</script>

<style scoped>
.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}
</style>
